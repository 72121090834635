<template>
    <div v-if="items">
        <b-table :items="items" :fields="fields" :tbody-tr-class="rowClass">
            <template #cell(date)="data">
                <span class="click-able"> {{ formatDate(data.item) }} </span>
            </template>
            <template #cell(total_success_order)="data">
                <span class="click-able"> {{ calTotalSuccessOrder(data.item) }} </span>
            </template>
            <template #cell(total_success_value)="data">
                <span class="click-able"> {{ calTotalSucessValue(data.item) }} </span>
            </template>
        </b-table>              
    </div>
</template>
<script>
import service from '../../service'
import XLSX from "xlsx"
export default {
    props:{
        items: {type: Array, default: null},
        is_loading: {type: Boolean, default: true},
        query: {type: Object, default: null}
    },
    data(){
        return {
            fields: [
                {
                    key: "date",
                    label: "Date",
                    thClass: "table-success",
                },
                {
                    key: "goga_premium_six_month",
                    label: "6m - Success Order",
                    thClass: "table-success"
                },
                {
                    key: "goga_premium",
                    label: "12m - Success Order",
                    thClass: "table-success"
                },
                {
                    key: "goga_premium_lifetime",
                    label: "Lifetime - Success Order",
                    thClass: "table-success"
                },
                {
                    key: "total_success_order",
                    label: "Total All Success Order",
                    thClass: "table-success"
                },
                {
                    key: "total_success_value",
                    label: "Total All Success Value",
                    thClass: "table-success"
                },
            ],
            current_item: null,
        }
    },
    watch:{
        items: {
            handler: function () {
                if (this.items.length && this.items[0].date != "Total"){
                    this.items.unshift(
                        {date: "Total", ...this.calTotalRow()}
                    )
                }
            },
            deep: true
        }
    },
    methods: {
        convertActivity(data){
            let curMission = this.missions.find((mission)=>{
                return mission._id == data.mission_id
            })
            return curMission ? curMission.name.translations[0].value : "Unknown"
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.date === 'Total') return 'table-success'
        },
        formatDate(data){
            if (data.date == "Total"){
                return "Total"
            }
            let date = this.dateFromDay(data._id.year, data._id.year_day);
            date = date.setDate(date.getDate() + 1)
            return new Date(date).toJSON().slice(0,10).split('-').reverse().join('/')  // add the number of days
        },
        dateFromDay(year, day){
            let date = new Date(year, 0); // initialize a date in `year-01-01
            let currentDate = new Date(date.setDate(day));
            return currentDate // add the number of days
        },
        async exportData(){
            let response = await service.getAll({query: JSON.stringify(this.query)});
            if (response.data.type === "DATA") {
                this.export_data = response.data.data.list;
            }
            this.export_data = this.export_data.map((result)=>{
                return {
                    date: this.formatDate(result),
                    goga_premium_six_month: result.goga_premium_six_month,
                    goga_premium: result.goga_premium,
                    goga_premium_lifetime: result.goga_premium_lifetime,
                    total_success_order: this.calTotalSuccessOrder(result),
                    total_success_value: this.calTotalSucessValue(result)
                }
            })
            this.export_data.unshift({date: "Total", ...this.calTotalRow()})
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.json_to_sheet(this.export_data, { origin: 'A2', skipHeader: true });
            const header = [['Date','6m - Success Order','12m - Success Order', 'Total All Success Order', 'Total All Success Value']];
            XLSX.utils.sheet_add_aoa(ws, header);
            XLSX.utils.book_append_sheet(wb, ws, 'Data');
            XLSX.writeFile(wb, `gift_report.xlsx`);
            this.export_results = [];
        },
        calTotalSuccessOrder(data){
            return data.goga_premium + data.goga_premium_six_month + data.goga_premium_lifetime
        },
        calTotalSucessValue(data){
            let total = 0;
            switch(this.query.language){
                case "vi":
                    total = data.goga_premium_six_month * 690000 + data.goga_premium * 990000 + data.goga_premium_lifetime * 2490000;
                    break;
                case "th":
                    total = data.goga_premium_six_month * 1490 + data.goga_premium * 1990 + data.goga_premium_lifetime * 3990;
                    break;
                case "id":
                    total = data.goga_premium_six_month * 590000 + data.goga_premium * 790000 + data.goga_premium_lifetime * 1590000;
                    break;
                case "en":
                    total = data.goga_premium_six_month * 39 + data.goga_premium * 49 + data.goga_premium_lifetime * 99;
                    break;
            }
            return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        calTotalRow(){
            let total = {
                goga_premium_six_month: 0,
                goga_premium: 0,
                goga_premium_lifetime: 0
            };
            this.items.forEach((item)=>{
                if (item.date != "Total"){
                    total.goga_premium_six_month += item.goga_premium_six_month;
                    total.goga_premium += item.goga_premium;
                    total.goga_premium_lifetime += item.goga_premium_lifetime;
                }
            });
            total.total_success_order = total.goga_premium_six_month + total.goga_premium + total.goga_premium_lifetime;
            total.total_success_value = this.calTotalSucessValue(total);
            return total
        }
    }
}
</script>
<style scoped>
    .click-able:hover {
        cursor: pointer;
        color: #1198F6
    }
</style>