<template>
    <div class="page-content">
        <b-row>
            <b-col md="10">
                <page-breadcrumb title="Gift Report" class="mb-3" />
            </b-col>
        </b-row>
         <b-media>
            <section class="py-1">
                <h2 class="mb-2">Gift Report</h2>
                <!-- <div class="mb-2 d-flex justify-content-end">
                    <b-button @click="exportData" class="mt-0 mt-md-2 mr-1" variant="success">
                        Export
                    </b-button>
                </div> -->
                <gift-report-search-form @getList="getList" @exportData="exportData" />
                <b-card>
                <gift-report-table
                    @getList="getList"
                    :items="giftReport"
                    :is-loading="isLoading"
                    :query="query"
                    ref="giftReportTable"
                />
                </b-card>
            </section>
        </b-media>
        <b-pagination
            class="mt-auto"
            pills
            size="lg"
            align="center"
            v-model="current_page"
            :total-rows="total_items"
            :per-page="items_perpage"
        />
        
    </div>
</template>
<script>
import GiftReportSearchForm from './_components/GiftReportSearchForm.vue'
import GiftReportTable from './_components/GiftReportTable.vue'
import service from "../service"
export default {
    components:{
        GiftReportSearchForm,
        GiftReportTable
    },
    data(){
        return {
            giftReport: [],
            total_items: 0,
            current_page: 1,
            items_perpage: 50,
            isLoading: true,
            export_data: [],
            query: {
                language: "vi",
                time_range: {
                    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                }
            }
        }
    },
    watch: {
        current_page() {
        this.getList();
        },
    },
    created(){
        this.getList();
    },
    methods: {
        async getList(query = null) {
            if (query){
                this.query = query;
            }
            let response = await service.getList({
                page: this.current_page,
                limit: this.items_perpage,
                query: JSON.stringify(this.query)
            });
            if (response.data.type === "DATA") {
                this.giftReport = response.data.data.list;
                this.total_items = response.data.data.total;
            }
            this.isLoading = false
        },
        exportData(){
            this.$refs.giftReportTable.exportData();
        }
    }

}
</script>