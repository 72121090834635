import api from '@/libs/axios'
import axios from 'axios'
const axiosPrivate = axios.create({
	baseURL: `${process.env.VUE_APP_API_URL}/api/private`,
	timeout: 18000,
	headers: {
		Authorization: "secret"
	},
  })
export default {
	async getList(params) {
		let response = await api.get('/admin/user-products/early-report', { params })
		return response
	},
	async getAll(params){
		let response = await api.get('/admin/user-products/early-report-all', { params })
		return response
	},
}